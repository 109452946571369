import React from 'react';
import Heading from '@legacy-vfuk/core-heading';
import { renderRichText } from '@vhub/gatsby-source-contentful/rich-text';
import MainLayout from '../layouts/MainLayout';
import options from '../helpers/ContentFulOptions';
import Block from '@legacy-vfuk/core-block';
import { StyledBody, transparent } from './NotFound.styles';
import { NotFoundProps } from './NotFound.types';

const NotFound = ({ heading, bodyObject }: NotFoundProps) => {
  return (
    <MainLayout>
      <Block className="section" css={transparent}>
        <div className="spring">
          <StyledBody>
            <Heading noMargin={false} level={1}>
              {heading}
            </Heading>
            <React.Fragment>
              {renderRichText(bodyObject, options)}
            </React.Fragment>
          </StyledBody>
        </div>
      </Block>
    </MainLayout>
  );
};

export default NotFound;
